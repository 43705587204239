@import '../../css/variables.scss';

.multiselectContainer {
  position: relative;
  width: 40%;

  .searchWrapper {
    position: relative;
    width: 100%;
    padding: 0.4em 0;
    border: 1px solid $border-gray;
    border-radius: 0.4rem;
    display: flex;

    .selectedOption {
      background-color: $border-gray;
      color: $white;
      padding: 0.45em;
      border-radius: 0.4rem;
      margin: 0 0.1em;
      white-space: nowrap;

      &:hover {
        background-color: $input-gray;
      }
    }

    .searchBox {
      border: none;
      width: 100%;
      padding: 0 0.5em;
      margin: 0;

      &:focus {
        outline: none;
      }
    }
  }

  .optionListContainer {
    position: absolute;
    background-color: $white;
    z-index: 1;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .optionsContainer {
      list-style: none;
      height: 50vh;
      overflow-y: auto;
      padding: 0;
      margin: 0;

      .option {
        padding: 0.3em 0.5em;
        display: grid;
        grid-template-columns: 5em 1fr;

        &:first-child {
          padding-top: 0.5em;
        }

        .optionCode {
          grid-column: 1 / span 1;
        }

        .optionDescription {
          grid-column: 2 / span 1;
        }

        &:hover {
          cursor: pointer;
          background-color: $background-gray;
        }
      }

      .disabledOption {
        color: $input-gray;
        background-color: $background-gray;

        &:hover {
          cursor: initial;
        }
      }
    }
  }
}

.hide {
  display: none;
}
