@import './css/variables.scss';

html,
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

.outerWrapper {
  position: absolute;
  top: 4rem;
  width: 100%;
}

label {
  display: block;
}

.error {
  padding: 10px 15px;
  background: red;
}

.btn {
  background: $white;
  display: block;
  outline: none;
  border: none;
  border-radius: 0.4rem;
  padding: 0.6rem 1rem;
  font-weight: 700;
  cursor: pointer;

  &.blue {
    background: $blue;
    color: $white;
  }

  &.warn {
    background: $red;
    color: $white;
  }

  &:hover:enabled {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    background: $gray;
    cursor: initial;
  }
}
