@import '../../css/variables.scss';


body {
  background: $background-gray;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  grid-template-areas:
    'evalues employees'
    'evalues actions';
  padding: 0 2rem;
  grid-template-rows: auto 1fr;
  padding-top: 2rem;

  .evalues {
    grid-area: evalues;
  }

  .employees {
    grid-area: employees;
  }

  .actions {
    grid-area: actions;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .total {
    border-left: 1px solid $gray;
    padding: 0 0 0 1rem;
  }

  .participants {
    display: grid;

    > button {
      grid-column: 2;
    }

    > .participantsText {
      font-size: 1rem;
      padding: 0.5em 0;
      grid-column: span 2;
      white-space: pre-wrap;
    }

    > textarea {
      font-family: inherit;
      resize: vertical;
      margin: 0.5em 0;
      border: 1px solid $border-gray;
    }
  }

  .bar {
    border: 1px solid #b3b4b4;
    margin: 0.5em 0;
  }

  .box {
    background: $white;
    border: 1px solid $gray;
    padding: 1.5rem;

    h1 {
      color: $blue;
      margin: 0;
      font-size: 24px;
    }

    h2 {
      color: $blue;
      margin: 0;
      font-size: 12px;
    }

    .companyNameButton {
      width: 90%;

      &:hover {
        cursor: pointer;
      }
    }

    input[type='text'] {
      color: $blue;
      margin: 0;
      font-size: 24px;
      display: block;
      font-weight: bold;
      border: 0;
      outline: none;
      border-bottom: 1px solid $white;
      padding-top: 0.5rem;
      cursor: pointer;

      &.active {
        border-bottom: 1px solid $input-gray;
        cursor: initial;
      }

      &:disabled {
        background-color: inherit;
        cursor: text;
      }
    }

    .meter {
      margin-top: 0.3rem;
      height: 20px;
    }
  }
}

.wrapper2 {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-gap: 2rem;
  grid-template-areas:
    'evalues employees'
    'evalues actions';
  padding: 0 2rem;
  grid-template-rows: auto 1fr;
  padding-top: 2rem;

  .evalues {
    grid-area: evalues;
  }

  .employees {
    grid-area: employees;
  }

  .actions {
    grid-area: actions;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .total {
    padding: 0 0 0 1rem;

      p {
        margin: 0;
        font-size: 0.6rem;
        color: $blue;
      }

      .emeterInformation {
          padding-top: 5px;
      }
  }

  .participants {
    display: grid;

    > button {
      grid-column: 2;
    }

    > .participantsText {
      font-size: 1rem;
      padding: 0.5em 0;
      grid-column: span 2;
      white-space: pre-wrap;
    }

    > textarea {
      font-family: inherit;
      resize: vertical;
      margin: 0.5em 0;
      border: 1px solid $border-gray;
    }
  }

  .bar {
    border: 1px solid #b3b4b4;
    margin: 0.5em 0;
  }

  .box {
    background: $white;
    border: 1px solid $gray;
    padding: 1.5rem;

    h1 {
      color: $blue;
      margin: 0;
      font-size: 24px;
    }

    h2 {
      color: $blue;
      margin: 0;
      font-size: 12px;
    }

    .companyNameButton {
      width: 90%;

      &:hover {
        cursor: pointer;
      }
    }

    input[type='text'] {
      color: $blue;
      margin: 0;
      font-size: 24px;
      display: block;
      font-weight: bold;
      border: 0;
      outline: none;
      border-bottom: 1px solid $white;
      padding-top: 0.5rem;
      cursor: pointer;

      &.active {
        border-bottom: 1px solid $input-gray;
        cursor: initial;
      }

      &:disabled {
        background-color: inherit;
        cursor: text;
      }
    }

    .meter {
      margin-top: 0.3rem;
      height: 20px;
    }
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  margin: 1rem 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $black;
  }

  &::before {
    margin-right: 0.5rem;
  }

  &::after {
    margin-left: 0.5rem;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1.5rem;

  thead {
    th {
      padding-bottom: 0.8rem;
      font-weight: 400;
      font-size: 1.1rem;
      text-decoration: none;

      span {
        padding: 0 0.5rem 0.1rem 0.5rem;
        border-bottom: 1px solid $black;
      }
    }
  }

  tr {
    border-top: 1px solid $border-gray;

    &:first-child {
      border-top: none;
    }
  }

  td {
    border-left: 1px solid $border-gray;
    padding: 0.7rem 1rem;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      padding: 0 1rem;
    }

    &.fitContent {
      width: 1%;
      white-space: nowrap;
    }
  }

  th {
    text-decoration: underline;
  }
}

.checkbox {
  color: $white;
  border-radius: 5px;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid $blue;
  outline: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: 0.2s;
  margin: 0;

  &:disabled {
    border-color: $gray;
  }

  &:checked {
    background-color: $blue;
    border-color: $blue;

    &::before {
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      font-size: 14px;
      content: '\f00c';
    }
  }
}

.select {
  border-radius: 0.2rem;
  border: 1px solid $border-gray;
  padding: 0.5rem;
  font-size: 14px;
  outline: none;
}

.categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-gap: 2rem;

  .separator {
    grid-column: 1 / -1;
  }

  .hover:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .card {
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.75rem;
    border: 1px solid $blue;
    border-radius: 0.75rem;
    color: $blue;
    text-decoration: none;
    text-align: center;

    .title {
      font-size: 1rem;
      font-weight: 700;
      text-decoration: none;
      margin-bottom: 0.45rem;
    }

    .button,
    .categoryMeter {
      margin: 0.1rem 0.5rem 1.65rem 0.5rem;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      font-weight: 650;
      color: $white;
    }

    .button {
      background: $blue;
      margin-top: 1.0rem;
    }

    .button,
    .categoryMeter > div {
      padding: 0.6rem 1rem;
    }

    .button.inactive {
      background-color: $white;
      color: $blue;
    }

    .categoryMeter {
      overflow: hidden;

      & > div {
        display: flex;
        justify-content: center;
      }
    }

    .categoryMeterText,
    .footer {
      display: flex;
      justify-content: center;
      align-content:flex-start;
      flex-direction: column;

      p {
        margin: 0;
        font-size: 0.8rem;
      }
    }

    .categoryMeterText {
      margin: 0rem 1.5rem;
      justify-content: center;

      p {
          font-style: italic;
      }
    }
  }
}

.TreeContainer {

  .DepartmentCard {
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: column;
    
    
    .DepartmentInfo, .DepartmentButton {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }

    .DepartmentButton {
      width: 90%;

      &:hover {
        cursor: pointer;
      }
    }

    p {
      width: 25%;
      padding: 0.25rem;
      margin: 0;
    }

    .name {
      font-weight: 700;
      text-decoration: none;
      width: 50%;
    }

    .nodata {
      margin-left: 10%;
      font-style: italic;
      color: $border-gray;
    }

    .SubDepartment {
      margin-left: 1rem;

      p {
        font-size: 95%;
      }

      button {
        font-size: calc(1.35rem*0.93);
      }
    }

    button {
      border: none;
      background-color: inherit;
      font-size: 1.35rem;
      font-weight: 700;
      margin-left: auto;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.wrapperRead {
  grid-template-areas:
    'departments evalues';

  .evalues {
    grid-area: evalues;
  }

  .departments {
      grid-area: departments;
  }
  .sidebar{
    height: 100%;
    width: 25rem;
    position: absolute;
    left: 2.5rem;
    top: 0;
    padding-top: 3rem;
    background-color: background-grey;

    h1 {
      color: $blue;
      margin: 0;
      font-size: 24px;
      
    }
    h2 {
      color: $blue;
      margin: 0;
      font-size: 12px;
    }
  }

  .branch{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

}

@media screen and (min-width: 1921px) {
  .wrapper {
    max-width: 1920px;
    margin: 0 auto;
  }
}
