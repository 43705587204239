// Colors
$blue: #00759a;
$lightblue: #85cddb;
$gray: #d5d6d2;
$black: #000000;
$white: #ffffff;
$red: #c75300;

$border-gray: #b3b4b4;
$input-gray: #616365;
$background-gray: #ededed;
