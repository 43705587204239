@import '../../css/variables.scss';

.backgroundimagewrapper {
  background-image: url('../../img/background.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.backgroundwhitewrapper {
  background: $background-gray;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  padding-top: 3rem;

  height: calc(100vh - 4rem);

  .header {
    color: $white;
    font-size: 1.5rem;
    text-align: center;
    flex-grow: 0;

    h1 {
      font-size: 3em;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1em;
      max-width: 30em;
      margin: 0 auto;
      margin-bottom: 3rem;
    }

    button {
      font-size: 1em;
      padding: 0.5em;
      width: 8em;
      border: none;
      outline: none;
      border-radius: 0.3rem;
      background-color: white;
      color: $blue;
      font-weight: 700;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .box {
    background: $white;
    border: 1px solid $gray;
    padding: 1.5rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
    min-width: 60%;

    h1 {
      color: $blue;
      margin: 0;
      font-size: 20px;
      margin-bottom: 0.3rem;
      margin-top: 1.5rem;
    }

    button {
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.3rem;
      padding: 0.5rem 1rem;
      font-weight: 700;

      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.content {
  padding: 1rem 3rem;

  .list {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      border-bottom: 1px gray solid;
      padding-bottom: 4px;

      a {
        color: $black;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;

        &:visited {
          color: $black;
        }

        .name {
          font-weight: 700;
          margin-right: 4rem;
        }

        .schemaName {
          opacity: 0.8;
          margin-left: 5rem;
        }

        .archivedname {
          color: $blue;
          font-weight: bold;
        }
      }

      .responsename {
        flex-grow: 1;
        color: $blue;
      }

      .responseLineContent {
        display: flex;
      }

      .evalueMeterContainer {
        width: 300px;
      }

      .deleteResponse {
        margin-right: auto;
      }

      /* @media screen and (min-width: 720px) {
        .deleteResponse {
          margin-left: 80px;
        }
      }

      @media screen and (min-width: 1920) {
        .deleteResponse {
          margin-left: 560px;
        }
      } */

      .delete {
        background: $blue;
        color: $white;
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
        font-size: 0.8rem;
        white-space: nowrap;
        width: fit-content;
      }

      .deleteArchive {
        background: $white;
        color: $blue;
        border: 1px solid $blue;
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
        font-size: 0.8rem;
        white-space: nowrap;
        width: fit-content;
      }
    }
  }

  .separator {
    color: $input-gray;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.8rem;
    margin: 4rem 0 2rem 0;

    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid $input-gray;
    }

    &::before {
      margin-right: 2rem;
    }

    &::after {
      margin-left: 2rem;
    }

    .icon {
      font-size: 20px;
      padding-left: 0.5rem;
    }
  }

  .newResponse {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      margin-right: 2rem;

      &:last-child {
        margin-right: 0;
      }
    }

    input[type='text'] {
      border: 0;
      outline: none;
      border-bottom: 1px solid $input-gray;
      padding: 0.7rem 0;
      font-size: 16px;
    }

    .select {
      border-radius: 0.2rem;
      border: 1px solid $border-gray;
      padding: 0.5rem;
      font-size: 14px;
      outline: none;
    }

    .add {
      background: $blue;
      color: $white;
      margin-right: 0.2rem;
      margin-bottom: 0.2rem;
      font-size: 0.8rem;
      white-space: nowrap;
      width: fit-content;
    }
  }
}

.tabs {
  width: calc(100% + 3rem);
  margin: -1.5rem -1.5rem 0 -1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid $gray;
  background: #408080;
  color: $white;

  span {
    flex: 1;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray;
    background: $border-gray;
    color: $white;
    font-weight: 700;
  }

  .selected {
    background: $white;
    color: $black;
    border-bottom: none;
  }

  .companytitle {
    padding-left: 2rem;
    flex-grow: 2;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }

  .showcompany {
    align-self: flex-end;
    float: right;
    width: fit-content;
    font-size: 1 rem;
    margin-right: 1rem;
    height: 2 rem;
    white-space: nowrap;
  }
}

.headerresponses {
  display: flex;
  justify-content: center;
  color: $blue;
}
