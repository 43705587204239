@import '../../css/variables.scss';

.AdminPage {
  margin: 2rem auto;
  padding: 2rem;
  max-width: 1080px;

  &.WithBackground {
    background: white;
  }

  .marginBottom {
    margin-bottom: 2.5rem;
  }

  > h1 {
    margin: 0.5rem;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .UserCardsContainer {
    background: white;

    .SubDepartment {
      margin-left: 3rem;
    }

    .UserCard {
      padding: 0.25rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      border-bottom: thin solid grey;

      > * {
        margin: 0.25rem;
      }

      .UserAttribute {
        flex: 1 16rem;

        > input,
        > span {
          display: block;
          width: 100%;
          padding: 0.5rem;
        }
      }

      .deleteButton{
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
        font-size: 0.8rem;
        white-space: nowrap;
        width:fit-content;
        height: 2rem
      }

      .UserButton {
        width: 100%;
      }

      .RowButtons {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1rem;
      }
    }

    .DepartmentSelectContainer {
      width: 85%;
      margin-top: 1rem;

      .DepartmentSelect {
        padding: 0.15rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .DepartmentAttribute {
          flex: 30%;
          margin: 0 0.5rem;

          > span {
          display: block;
          width: 100%;
          padding: 0.5rem;
        }

        .deleteAccessButton{
          margin-right: 0.2rem;
          margin-bottom: 0.2rem;
          font-size: 0.8rem;
          white-space: nowrap;
          width:fit-content;
          height: 2rem
        }
        }
      }
    }

    .NaceCodeList {
      padding: 0;
      margin: 0;

      .NaceCode {
        font-size: 0.9em;
        color: $input-gray;
        padding-bottom: 0.2em;
      }
    }
  }
}

.ButtonControls {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  margin-top: 1em;
}

.SchemaProperties {
  background: white;
  padding: 1em;
  margin: 1em 0;
}

.Input {
  input {
    font-size: 1em;
  }

  input[type='text'] {
    width: 100%;
  }
}

.Category {
  margin: 4em 0;
  border: thin solid grey;
  background: white;

  .CategoryFields {
    padding: 1em;
    border-bottom: thin solid grey;
  }

  .Section {
    margin-top: 1em;
    padding: 0 1em 1em 1em;
    border-bottom: thin solid grey;
  }

  .Questions {
    display: grid;
    padding: 0.75em;
    grid-gap: 0.75em;

    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      > button:first-child {
        margin-right: 0.5em;
      }

      > label:first-of-type {
        flex: 1;
        min-width: 20em;
      }

      > label > input[type='number'] {
        width: 4em;
      }
    }
  }
}

.nace h1 {
  cursor: pointer;
  margin-bottom: 1rem;
}

.naceHidden {
  opacity: 0.7;
  margin: 2rem auto;
  padding: 0 2rem;
  max-width: 1080px;

  &:hover {
    opacity: 1;
  }
}

.naceFileUpload {
  display: flex;
  margin-top: 1em;

  > * {
    margin: 0.25rem;
  }
}
