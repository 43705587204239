@import "../../css/variables.scss";

.toast-container {
  position: fixed;
  bottom: 0;
  right: 0;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 100;
  align-items: end;

  .toast {
    background: white;
    padding: .5em;
    margin: 1px;
    display: flex;
    align-items: baseline;

    &.waiting {
      border-top: 2px solid $border-gray;
    }

    &.success {
      border-top: 2px solid #408080;
    }

    &.error {
      border-top: 2px solid $red;
      color: $red;

      .message {
        font-weight: bold;
      }
    }

    .close-button {
      width: auto;
      margin-left: .5em;
    }
  }
}
