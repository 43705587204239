@import '../../css/variables.scss';

.navbar {
  background: $blue;
  color: $white;
  display: flex;
  height: 4rem;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;

  .logo {
    height: 100%;
    margin-right: 1rem;

    img {
      max-height: 100%;
    }
  }

  .navLinks {
    padding: 0;
    margin: 0;
    list-style: none;

    .navItem {
      display: inline-block;

      a {
        color: $white;
        font-size: 18px;
        text-decoration: none;
        margin-left: 1.5rem;
      }
    }
  }

  .right {
    margin-left: auto;
    margin-right: 3rem;

      @media (max-width: 1700px) {
        margin-right: 0rem;
    }

    .customer {
      font-size: 24px;
    }
  }
}

.select {
  border-radius: 0.2rem;
  border: none;
  padding: 0.5rem;
  font-size: 14px;
  outline: none;
  margin-right: 2rem;
  background-color: transparent;
  color: white;

    @media (max-width: 1700px) {
        margin-right: 0rem;
    }


  option {
      background-color: $blue;
      color: white;
      border: none;
  }
}

button {
    font-size: 1em;
    padding: 0.5em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    width: 8em;
    border: none;
    outline: none;
    border-radius: 0.3rem;
    background-color: white;
    color: $blue;
    font-weight: 700;
    cursor: pointer;

    @media (max-width: 1700px) {
        padding: 0.2em;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
        width: 5em;
    }

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}
