@import '../../css/variables.scss';

.landingPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('../../img/background.jpg');
  background-position: center;
  background-size: cover;

  .logo {
    padding: 1.5rem 2rem;
    flex-grow: 0;
    width: 12%;

    img {
      width: 100%;
    }
  }

  .content {
    padding: 0 8vw;
    margin: auto 0;
    color: $white;
    font-size: 1.5rem;
    text-align: center;

    h1 {
      font-size: 4em;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1em;
      max-width: 30em;
      margin: 0 auto;
      margin-bottom: 7rem;
    }

    div {
      display: flex;
      margin-top: 1em;
    }

    .buttonGroup {
      display: flex;
      justify-content: center;
    }

    button {
      font-size: 1em;
      margin-right: 2.5em;
      padding: 0.5em;
      width: 8em;
      border: none;
      outline: none;
      border-radius: 0.25em;
      background-color: white;
      color: $blue;
      font-weight: 700;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
