@import '../../css/variables.scss';

.meter {
    /*  background: var(--background);*/
    color: $blue;
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transition: width 0.3s;
    background-image: linear-gradient(to right, green, yellow, orange, red);
    clip-path: polygon(0 0, var(--size) 0, var(--size) 100%, 0% 100%);
}

.meterBox {
    padding-left: 5px;
    display: flex;
    color: $blue;
    font-weight: bold;
}

.metercontainer {
    border: 1px solid $blue;
}

.metertext {
    padding-left: 5px;
}
