@import '../../css/variables.scss';

.outerWrapper {
  display: flex;
  flex-flow: column;
  height: calc(100vh - 10rem);
  position: absolute;
  top: 6rem;
  width: 100%;
}

.heading {
  background: $white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 4rem;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid $gray;

  .progress {
    display: flex;
    margin: 0 auto;
    align-items: center;

    .meterContainer {
      margin-left: 2rem;
      width: 30rem;
      height: 1.7rem;
      border: 1px solid $border-gray;
    }
  }

  .backButton {
    background: $blue;
    color: $white;
    border: none;
    outline: none;
    height: 3rem;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding-right: 1em;
    font-weight: 700;

    .icon {
      font-size: 40px;
      padding: 0 0.25em;
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  h1 {
    color: $blue;
    font-size: 30px;
    margin: 0 0 0 2.5rem;
    display: inline-block;
  }

  label {
    margin-left: 0.3rem;
  }
}

.wrapper {
  background: $white;
  display: flex;
  flex-grow: 1;

  .content {
    width: 100%;

    .header {
      background: $gray;
      text-align: center;

      h1 {
        font-size: 24px;
        margin: 0;
      }
    }
  }

  .menu {
    width: 27%;
    background: $blue;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-shrink: 0;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      color: $white;
      height: 3.75rem;
      padding: 0 2rem;
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      text-decoration: none;

      &.active,
      &:hover {
        background: $gray;
        color: $black;
      }

      .circle {
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        margin-right: 1rem;
        border-radius: 50%;
        border: 2px solid $white;

        &.modified {
          background: $white;
        }
      }

      .icon {
        margin-left: auto;
        font-size: 28px;
      }

      &.active .circle,
      &:hover .circle {
        border-color: $black;
      }

      &.active .circle.modified {
        background: $black;
      }

      &:hover .circle.modified {
        background: $black;
        border-color: $black;
      }

      &.disabled {
        pointer-events: none;
        color: $gray;

        & > .icon {
          color: transparent;
        }
      }
    }
  }
}
