@import '../../css/variables.scss';

h1 {
  margin: 0;
  font-size: 24px;
}

.heading {
  background: $gray;
  text-align: center;
  padding: 1.5rem 0;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem 8rem;
  grid-template-areas:
    'description notes'
    'checkboxes actions';
  padding: 4rem 5rem;

  h1 {
    color: $blue;
    margin-bottom: 1rem;
  }

  button {
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    font-weight: 700;

    &:disabled {
      background: $gray;
      color: $black;
      cursor: initial;
    }

    &:hover:enabled {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .description {
    grid-area: description;
    white-space: pre-wrap;
  }
  .checkboxes {
    grid-area: checkboxes;

    ul {
      list-style: none;
      padding: 0;

      .checkboxContainer {
        display: flex;
        margin-bottom: 2.5rem;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }

        label {
          padding-left: 2rem;
        }

        .checkbox {
          color: $white;
          border-radius: 7px;
          width: 2rem;
          height: 2rem;
          border: 2px solid $blue;
          outline: none;
          -webkit-appearance: none;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          transition: 0.2s;

          &:disabled {
            border-color: $gray;

            & + label {
              color: $input-gray;
            }

            &:checked {
                background-color: $gray;
                border-color: $gray;
            }
          }

          &:checked {
            background-color: $blue;
            border-color: $blue;

            &::before {
              font-family: 'Font Awesome\ 5 Free';
              font-weight: 900;
              font-size: 18px;
              content: '\f00c';
            }
          }
        }
      }
    }
  }
  .notes {
    grid-area: notes;

    textarea {
      width: 100%;
      height: 8rem;
      resize: vertical;
      border: 1px solid $border-gray;
      border-radius: 5px;
      padding: 0.8rem;
      font-family: inherit;
      font-size: 16px;

      &:focus {
        outline: none;
      }
    }
  }
  .actions {
    grid-area: actions;

    h1 {
      margin-bottom: 2rem;
    }

    .box {
      border: 1px solid $border-gray;
      padding: 1.2rem;

      .list {
        list-style: none;
        counter-reset: inputs;
        padding: 0;

        li {
          counter-increment: inputs;
          margin-bottom: 2.5rem;
          display: flex;
          align-items: flex-end;

          &::before {
            content: counter(inputs) ': ';
            border-bottom: 1px solid $input-gray;
            font-weight: bold;
            padding-right: 0.3rem;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          .deleteButton {
            background: $red;
            color: $white;
            margin-left: 1rem;
            padding: 0.3rem 0.8rem;

            &:disabled {
              background: $gray;
              color: $black;
            }
          }
        }
      }

      .input {
        border: none;
        border-bottom: 1px solid $input-gray;
        flex-basis: 100%;
        font-size: 1em;

        &:focus {
          outline: none;
        }
      }

      .addButton {
        background: $blue;
        color: $white;
        margin-top: 1.5rem;

        &:disabled {
          background: $gray;
          color: $black;
        }
      }
    }
  }
}

.saveButton {
  background: $blue;
  color: $white;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 7px;
  padding: 0.6rem 1rem;
  cursor: pointer;

  &:disabled {
    background: $gray;
    color: $black;
    cursor: initial;
  }

  &:hover:enabled {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
