@import '../../css/variables.scss';

h1 {
  margin: 0;
  font-size: 24px;
}

.heading {
  background: $gray;
  text-align: center;
  padding: 1.5rem 0;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem 8rem;
  grid-template-areas:
    'description notes'
    'exposure empty'
    'save empty';
  padding: 4rem 5rem;

  h1 {
    color: $blue;
    margin-bottom: 1rem;
  }

  .description {
    grid-area: description;
    margin-right: 4rem;
    white-space: pre-wrap;
  }

  .exposure {
    grid-area: exposure;
    margin-right: 4rem;

    ul {
      list-style: none;
      padding: 0;

      .radioButtonContainer {
        display: flex;
        margin-bottom: 2.5rem;
        align-items: center;

        label {
          padding-left: 2rem;
        }

        .radioButton {
          color: $white;
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          border: 2px solid $blue;
          outline: none;
          -webkit-appearance: none;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          transition: 0.2s;

          &:checked {
            &::before {
              content: '';
              background: $blue;
              display: inline-block;
              width: 65%;
              height: 65%;
              border-radius: 50%;
            }
          }

          &:disabled {
            border-color: $gray;

            &:checked::before {
              background: $input-gray;
            }
          }
        }
      }
    }
  }

  .notes {
    grid-area: notes;

    textarea {
      width: 100%;
      height: 8rem;
      resize: vertical;
      border: 1px solid $border-gray;
      border-radius: 5px;
      padding: 0.8rem;
      font-family: inherit;
      font-size: 16px;

      &:focus {
        outline: none;
      }
    }
  }

  .saveButton {
    grid-area: save;
    background: $blue;
    color: $white;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 7px;
    padding: 0.6rem 1rem;
    cursor: pointer;

    &:disabled {
      background: $gray;
      color: $black;
      cursor: initial;
    }

    &:hover:enabled {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

textarea {
    resize: vertical;
}
